import { User, UserGeogroup } from '@geovelo-frontends/commons';
import { createContext } from 'react';

type TUserContext = {
  companyGroups: UserGeogroup[] | null | undefined;
  current: User | null | undefined;
  geogroups: UserGeogroup[] | null | undefined;
  signingOut: boolean;
};

type TActionsContext = {
  displayFooter: (displayed: boolean) => void;
  getCurrentUserGeogroups: () => Promise<void>;
  setCurrentUser: (user: User | null | undefined) => void;
  signOut: () => void;
  toggleTransparentBackground: (enabled: boolean) => void;
};

type TAppContext = {
  actions: TActionsContext;
  app: { footerDisplayed: boolean; transparentBackgroundEnabled: boolean };
  user: TUserContext;
};

export const AppContext = createContext<TAppContext>({
  app: { footerDisplayed: true, transparentBackgroundEnabled: false },
  user: { current: undefined, geogroups: undefined, companyGroups: undefined, signingOut: false },
  actions: {
    toggleTransparentBackground: () => undefined,
    displayFooter: () => undefined,
    setCurrentUser: () => undefined,
    getCurrentUserGeogroups: async () => await undefined,
    signOut: () => undefined,
  },
});
