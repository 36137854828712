import { HttpService, UserService, i18nCommons, setLocales } from '@geovelo-frontends/commons';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';

import { AppContext } from '../context';

import routes from './routes';

function Router(): JSX.Element {
  const [initialized, setInitialized] = useState(false);
  const {
    actions: { setCurrentUser },
  } = useContext(AppContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { i18n } = useTranslation();

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      const userId = searchParams.get('user-id');
      const authorizationToken = searchParams.get('auth-token');

      searchParams.delete('user-id');
      searchParams.delete('auth-token');

      setSearchParams(searchParams);

      if (userId && authorizationToken) {
        try {
          HttpService.authorizationToken = `Token ${authorizationToken}`;
          localStorage.setItem('authorization_token', `Token ${authorizationToken}`);
          localStorage.setItem('user_id', `${userId}`);
        } catch {
          console.error('localStorage access is denied');
        }
      }

      initUser();
    }
  }, [initialized]);

  useEffect(() => {
    i18nCommons.changeLanguage(i18n.language);
    setLocales(i18n.language);
  }, [i18n.language]);

  async function initUser() {
    try {
      const user = await UserService.getCurrentUser();

      setCurrentUser(user);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
      }
    }
  }

  return (
    <Routes>
      {routes.map(({ element, ...otherProps }) => {
        return <Route element={element} key={otherProps.path || 'index'} {...otherProps} />;
      })}
      <Route element={<Navigate to="/" />} path="*" />
    </Routes>
  );
}

export default Router;
